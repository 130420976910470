<template>
  <div>
    <c-table
      ref="table"
      title="내부심사 체크리스트 부적합/관찰사항 목록"
      :columns="grid.columns"
      :data="gridData"
      :gridHeight="grid.height"
      :merge="grid.merge"
      :editable="false"
      :isExcelDown="false"
      :filtering="false"
      :columnSetting="false"
    >
      <template slot="suffixTitle">
        <q-radio v-model="check" size="sm" checked-icon="task_alt" unchecked-icon="panorama_fish_eye" dense keep-color color="primary" class="text-primary" :val="false" label="전체보기" />
        <q-radio v-model="check" size="sm" checked-icon="task_alt" unchecked-icon="panorama_fish_eye" dense keep-color color="red" class="text-red" :val="true" label="부적합/관찰사항 보기" />
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
          <q-chip
            v-for="(item, index) in $comm.ibmTagItems(props.row, colorItems)"
            :key="index"
            outline square
            :color="item.color"
            :clickable="true"
            text-color="white"
            style="margin-bottom:4px !important;"
            @click="linkClick(item, props.row)">
            {{item.title}}
            <c-assess-tooltip 
              :ibmTooltip="item.ibmTooltip"
              :ibmClassCd="item.ibmClassCd"
            />
          </q-chip>
        </template>
        <template v-else>
        </template>
        <div v-if="editable && Boolean(props.row.saiInternalAuditChecklistId) && props.row.editFlag !== 'C' && !disabled">
          <q-btn-group outline class="ColumInnerBtnGroup">
            <q-btn
              icon="add"
              color="red-6"
              text-color="white"
              class="ColumInnerBtn"
              align="center"
              @click.prevent="innerBtnClicked(col, props)">
              <q-tooltip>
                개선요청등록
              </q-tooltip>
            </q-btn>
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'internal-action-improve',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        id: '',
      }),
    },
    action: {
      type: Object,
      default: () => ({
        id: '',  // 일련번호
        plantCd: '',
        col1: '', // 심사구분
        col2: '', // 심사 준비 요청사항
        col3: '', // 심사 목적 및 범위
        regDtStr: '',
        regUserName: '',
        auditMonthDepts: [], // 심사일정 - 피심사팀에 따른
        auditTeams: [], // 심사팀
        distributeTeams: [], // 배포부서
        checklistResult: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      check: false,
      grid: {
        merge: [
          { index: 0, colName: 'saiSystemElementsCd' },
        ],
        columns: [
          {
            name: 'saiSystemElementsName',
            field: 'saiSystemElementsName',
            label: '관련표준',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          // {
          //   name: 'sortOrder',
          //   field: 'sortOrder',
          //   label: '순서',
          //   align: 'center',
          //   style: 'width:30px',
          //   sortable: false,
          // },
          {
            name: 'itemName',
            field: 'itemName',
            label: '심사 항목',
            align: 'left',
            style: 'width:450px',
            sortable: false,
            type: 'html',
          },
          {
            name: 'aimMatter',
            field: 'aimMatter',
            label: '비고',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'html',
          },
          {
            name: 'result',
            field: 'result',
            label: '내부심사결과',
            child: [
              {
                name: 'sar0000001',
                field: 'sar0000001',
                label: '적합',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                checkIcon: 'task_alt',
                uncheckIcon: 'radio_button_unchecked',
                'true': 'Y',
                'false': 'N',
                color: 'green', 
              },
              {
                name: 'sar0000005',
                field: 'sar0000005',
                label: '부적합',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                checkIcon: 'task_alt',
                uncheckIcon: 'radio_button_unchecked',
                'true': 'Y',
                'false': 'N',
                color: 'green', 
              },
              // {
              //   name: 'sar0000010',
              //   field: 'sar0000010',
              //   label: '관찰사항',
              //   align: 'center',
              //   style: 'width:40px',
              //   sortable: false,
              //   type: 'check',
              //   checkIcon: 'task_alt',
              //   uncheckIcon: 'radio_button_unchecked',
              //   'true': 'Y',
              //   'false': 'N',
              //   color: 'green', 
              // },
              // {
              //   name: 'sar0000015',
              //   field: 'sar0000015',
              //   label: '비적용',
              //   align: 'center',
              //   style: 'width:40px',
              //   sortable: false,
              //   type: 'check',
              //   checkIcon: 'task_alt',
              //   uncheckIcon: 'radio_button_unchecked',
              //   'true': 'Y',
              //   'false': 'N',
              //   color: 'green', 
              // },
            ]
          },
          {
            name: 'observation',
            field: 'observation',
            // label: '관찰내용<br/>(부적합사항<br/>및 보고서No.)',
            label: '관찰내용',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          // {
          //   name: 'customCol',
          //   field: 'customCol',
          //   label: 'LBLIMPRNO_PROGRESS',
          //   align: 'center',
          //   style: 'width:150px',
          //   type: 'custom',
          //   sortable: false
          // },
        ],
        data: [],
        height: '550px', // '810px',
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridData() {
      let _gridData = this.action.checklistResult;
      if (this.check) {
        _gridData = this.action.checklistResult && this.action.checklistResult.length > 0 ? 
          this.action.checklistResult.filter(data => data.sar0000005 === 'Y' || data.sar0000010 === 'Y') : []
      }
      return _gridData;
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
    },
    /* eslint-disable no-unused-vars */
    innerBtnClicked(col, props, btn) {
      window.getApp.$emit('ALERT', {
        title: 'LBLGUIDE', // 안내
        message: '준비중',
        type: 'info', // success / info / warning / error
      });
      // this.requestImprRow = props.row
      // let requestContents = props.row.saiSystemElementsName;
      // if (props.row.itemName) {
      //   requestContents += ' / ' + props.row.itemName
      // }
      // this.popupOptions.title = 'LBLIMPRREQUEST';
      // this.popupOptions.param = {
      //   requestContents: requestContents,
      //   relationTableKey: props.row.saiInternalAuditChecklistId,
      //   ibmTaskTypeCd: 'ITT0000001',
      // };
      // this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequest.vue'}`);
      // this.popupOptions.width = '80%';
      // this.popupOptions.visible = true;
      // this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let sData = this.requestImprRow.sopImprovementIds ? this.requestImprRow.sopImprovementIds : '';
          if (sData.indexOf(item.sopImprovementId) === -1) {
            this.requestImprRow.sopImprovementIds = this.requestImprRow.sopImprovementIds ? 
              this.requestImprRow.sopImprovementIds + ',' + item.sopImprovementId : item.sopImprovementId
            this.requestImprRow.ibmStepNames = this.requestImprRow.ibmStepNames ? 
              this.requestImprRow.ibmStepNames + ',' + item.ibmStepName : item.ibmStepName
            this.requestImprRow.ibmClassCds = this.requestImprRow.ibmClassCds ? 
              this.requestImprRow.ibmClassCds + ',' + item.ibmClassCd : item.ibmClassCd
            this.requestImprRow.ibmTooltip = this.requestImprRow.ibmTooltip ? 
              this.requestImprRow.ibmTooltip + '│' + item.ibmTooltip : item.ibmTooltip
          }
        })
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.research();
      }
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: data.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          if (result.col1.ibmStepCd === 'IS00000010' || result.col1.ibmStepCd === 'IS00000015' || result.col1.ibmStepCd === 'IS00000020') {
            this.requestImprRow.riskRegisterFlag = true
          }
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
          }
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.research();
      }
    },
    saveChecklist() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGSAVE', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          // this.$_.forEach(this.node.hazopScenarios, item => {
          //   item.regUserId = this.$store.getters.user.userId
          //   item.chgUserId = this.$store.getters.user.userId
          // })
          
          // this.isSave = !this.isSave
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>